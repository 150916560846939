/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/assets/scss/material-kit-react.scss'
import './src/assets/jss/material-kit-react.js'

require('./src/assets/jss/material-kit-react.js')
require('./src/assets/scss/material-kit-react.scss')
require('./src/assets/css/font_awesome/css/all.min.css')
require('typeface-roboto')